import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

export type Chatbot = {
  id: string;
  name: string;
  organisation: string;
  code: string;
  link: string;
  qrcode: string;
  active: boolean;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  tabValue: "grid" | "list";
  chatbots: Chatbot[];
  currentPage: number;
  showCreateBotDialog: boolean;
  token: string;
  search: any;
  chatBotId: any;
  pagination: {
    total: number;
    itemPerPage: number;
    currentPage: number;
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Catalogue1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllChatBotId: string|null= "";
  deleteChatBotId: string|null= "";
  duplicateChatBotId: string|null= "";
  updateChatBotId: string|null= "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      isLoading: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      tabValue: "grid",
      chatbots: [],
      currentPage: 1,
      showCreateBotDialog: false,
      token: "",
      search: "",
      chatBotId: null,
      pagination: {
        total: 0,
        itemPerPage: 16,
        currentPage: 1
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handleResponseForGetAllChatbotsData(message)
    this.handleResponseForDeleteChatbot(message)
    this.handleResponseForDuplicateChatbot(message)
    this.handleResponseForUpdateChatbotStatus(message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  onTabChange = (newValue: "grid" | "list") => {
    this.setState({tabValue: newValue})
  }

  onPageChange = (event: any, newPage: number) => {
    this.setState((prev) => ({pagination: {...prev.pagination, currentPage: newPage}}), () => this.getAllChatbots())
  }

  onToggleCreateBotDialog = () => {
    this.setState({showCreateBotDialog: !this.state.showCreateBotDialog})
  }

  handleDeleteChatBotId = (id: number)=>{
    this.setState({chatBotId: id, isLoading: true}, () => this.deleteChatbot(this.state.chatBotId))
  }

  handleDuplicateBotId = (id: number)=>{
    this.setState({chatBotId: id, isLoading: true}, () => this.duplicateChatbot(this.state.chatBotId))
  }

  handleBotStatusChange = (id: number) => {
    const chatbots = this.state.chatbots.map(bot => {
      if(+bot.id === id) return {...bot, active: !bot.active}
      return bot
    })
    this.setState({chatbots, chatBotId: id}, () => this.updateChatbotStatus(this.state.chatBotId))
  }

  handleSearchChange = (event: any) => {
    sessionStorage.removeItem("search")
    this.setState({search: event.target.value}, () => this.getAllChatbots())
  }

  navigateToEditPage = (id: number) => {
    let navigationMessage = new Message(getName(MessageEnum.NavigationFormsMessage))
    navigationMessage = new Message(getName(MessageEnum.NavigationIndividualBotMessage))
    navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), id)
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      { navigation: this.props.navigation }
    )
    this.send(navigationMessage)
  }
  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    const search = sessionStorage.getItem("search") || ""
    this.setState({ token, isLoading: true, search })
    this.getAllChatbots();
    sessionStorage.removeItem("search")
  }

  getAllChatbots = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllChatBotId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBotApiEndpoint}?search=${this.state.search}&per_page=${this.state.pagination.itemPerPage}&page=${this.state.pagination.currentPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  deleteChatbot = async (id: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteChatBotId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBotApiEndpoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  duplicateChatbot = async (id: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.duplicateChatBotId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBotApiEndpoint}/${id}/create_duplicate_bot`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateChatbotStatus = async (id: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateChatBotId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createBotApiEndpoint}/${id}/update_status`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleResponseForGetAllChatbotsData = async (message: Message) => {
    if (
      this.getAllChatBotId !== null &&
      this.getAllChatBotId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.chatbots?.data) {
        const chatbots = responseJson.chatbots.data.map((data: { attributes: { id: any; your_brand: any; name: any; general_link: any; iframe_link: any; qr_image: any; status: string; }; }) => ({
          id: data.attributes.id,
          organisation: data.attributes.your_brand,
          name: data.attributes.name.trim(),
          code: data.attributes.general_link,
          link: data.attributes.iframe_link,
          qrcode: data.attributes.qr_image,
          active: data.attributes.status === "active" ? true : false
        })) as Chatbot[]
        const {total_items: total, per_page: itemPerPage, current_page: currentPage} = responseJson.meta
        this.setState({chatbots: chatbots, isLoading: false, pagination: {total, itemPerPage, currentPage}})
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      }
    }
  }
  handleResponseForDeleteChatbot = async (message: Message) => {
    if (
      this.deleteChatBotId !== null &&
      this.deleteChatBotId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({isLoading: false, chatBotId: null})
      if (responseJson?.message) {
        let page = this.state.pagination.currentPage
        if (this.state.chatbots.length === 1 && this.state.pagination.currentPage > 1) {
          page = this.state.pagination.currentPage - 1
        }
        this.setState((prev) => ({
          pagination: {
            ...prev.pagination,
            currentPage: page
          }
        }))
        toast.success("The chatbot was successfully deleted")
        this.getAllChatbots()
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      } else if(responseJson?.error){
        toast.error(responseJson.error || "Please try again")
      }
    }
  }
  handleResponseForDuplicateChatbot = async (message: Message) => {
    if (
      this.duplicateChatBotId !== null &&
      this.duplicateChatBotId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({isLoading: false, chatBotId: null})
      if (responseJson?.data) {
        toast.success("The duplicate chatbot was successfully created")
        this.getAllChatbots()
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      } else if(responseJson?.errors?.length){
        toast.error(responseJson.error[0] || "Please try again")
      }
    }
  }
  handleResponseForUpdateChatbotStatus = async (message: Message) => {
    if (
      this.updateChatBotId !== null &&
      this.updateChatBotId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.chatbot) {
        this.setState({chatBotId: null})
      } else if (responseJson?.errors?.length > 0 && responseJson?.errors?.[0]?.token) {
        await this.handleLogout()
      }
    }
  }
  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()
    const message: Message = new Message(
      getName(MessageEnum.NavigationSignupLoginMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
