import React from "react";
import {
    Avatar,
    Grid,
    makeStyles,
} from "@material-ui/core";
import {
    CodeRounded,
    LinkRounded,
} from '@material-ui/icons';
import type { Chatbot } from "../Catalogue1Controller";
import QRCode from "./QRCode.web";
import { utils } from "../utils.web";
import ChatbotMoreActionsMenuButton from "./ChatbotMoreActionsMenuButton.web";

const {copytoClipboard} = utils

type ChatbotCardProps = Chatbot & {navigateToEditPage: any, handleDeleteBot: any, handleDuplicateBot: any};

const useStyles = makeStyles({
    card: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        gap: 4,
        padding: 24,
        backgroundColor: "#FFFFFF",
        border: "1px solid #CBD5E1",
        borderRadius: 8,
        boxSizing: "border-box",
        height: "100%",
        '& p': {
            margin: 0,
        },
    },
    cardBody: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
    },
    cardDetail: {
        display: "flex",
        gap: 8,
        '& .relative': {
            position: "relative",
            height: "max-content",
        },
    },
    cardAvatar: {
        height: 44,
        width: 44,
        boxSizing: "border-box",
        backgroundColor: "#D1FAE5",
        border: "2px solid #34D399",
        '& > span': {
            color: "#059669",
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: 18,
            lineHeight: "26px",
        },
    },
    circleDot: {
        position: "absolute",
        bottom: -1,
        right: -3,
        height: 14,
        width: 14,
        border: "2px solid #FFFFFF",
        borderRadius: 20,
        zIndex: 2,
    },
    chatbotTitle: {
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "22px",
        color: "#0F172A",
    },
    chatbotCreatorName: {
        fontFamily: "Cairo",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "22px",
        color: "#64748B",
    },
    cardActions: {
        display: "flex",
        gap: 8,
        paddingLeft: 52,
        '& button': {
            all: "unset",
            cursor: "pointer",
        },
    },
})

export default function ChatbotCard({ id, name, organisation, code, link, qrcode, active, navigateToEditPage, handleDeleteBot, handleDuplicateBot }: ChatbotCardProps) {
    const classes = useStyles()

    return (
        <Grid item xl={2} lg={3} md={4} sm={6} xs={12} >
            <div className={classes.card}>
                <div className={classes.cardBody}>
                    <div className={classes.cardDetail} >
                        <div className="relative" >
                            <Avatar className={classes.cardAvatar} >
                                <span>{`${name.charAt(0)}`}</span>
                            </Avatar>
                            <div className={classes.circleDot} style={{ backgroundColor: active ? "#51ABB2" : "#CBD5E1" }} />
                        </div>
                        <div>
                            <p className={classes.chatbotTitle} >{name}</p>
                            <p className={classes.chatbotCreatorName} >{organisation}</p>
                        </div>
                    </div>
                    <div className={classes.cardActions}>
                        <button onClick={() => copytoClipboard(code, "iFrame code copied to clipboard")} aria-label={`copy iframe code of ${name}`} >
                            <CodeRounded htmlColor="#475569" />
                        </button>
                        <button onClick={() => copytoClipboard(link, "URL copied to clipboard")} aria-label={`copy link of ${name}`} >
                            <LinkRounded htmlColor="#475569" />
                        </button>
                        {active && <QRCode id={id} botName={name} botQRCodeLink={qrcode} />}
                    </div>
                </div>
                <ChatbotMoreActionsMenuButton
                    id={id}
                    navigateToEditPage={navigateToEditPage}
                    handleDeleteBot={handleDeleteBot}
                    handleDuplicateBot={handleDuplicateBot}
                />
            </div>
        </Grid>
    )
}
