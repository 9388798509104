import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { SelectedCard } from "./components/ChatbotSidePanelController";
import { NodeData } from "../../../components/src/CardNode.web";
import { utils } from "./utils.web";
import { nodeHelpers } from "./nodeHelpers.web";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import React from "react";
import { Node } from "reactflow";


export type ConnectorCard = {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    message: string | null;
    chatbot_id: number;
    card_type: SelectedCard;
    card_wait_time: number | null;
    option: string[];
    field_name?: string | null;
    minimum_selection?: number | null;
    maximum_selection?: number | null;
    status: "connected"| "disconnected";
    connector_card_type: "chatbot" | "template";
    source: string[];
    source_with_template: string[];
    video_link: string | null;
    target_card: number |null;
    connection_type: string | null;
    user_guides?: string[];
    embeded_forms?: string[];
    previous_card_id: number | null;
    connector_card_links: Array<{
      id: number;
      link_name: string;
      link_type: string;
      open_link: string;
      connector_icon_image_link: string | null;
      link_url: string;
    }>;
    criteria_routings: Array<{
      criteria_routing_id: number;
      criteria_routing_title: string;
      next_card_id: number | null;
      rules: Array<{
        rule_id: number;
        field_name: string;
        values: string[];
        comparator: string;
        operator_with_previous_index: string;
        sub_field: string | null;
      }>
    }>
  };
}

type UpdateCriteriaRoutingArgs = {
  newActions: NodeData[];
  criteriaCardId: number;
  criteriaRoutingId: number;
  nextCardId: string | null | undefined;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isTemplate?: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string
  enableField: boolean;
  // Customizable Area Start
  isDrawerOpen: boolean;
  selectedCard: SelectedCard | null;
  selectedNodeData: {[nodeId: number]: Omit<NodeData,"status" | "onClick">} | undefined;
  selectedNodeId: number | undefined;
  actions: NodeData[];
  token: string;
  connectorCardLoading: boolean;
  source: string[];
  cardDeleteLoading: boolean;
  openDeleteConfirmDialog: boolean;
  showChatbotErrorDialog: boolean;
  hasErrorInTheChatbot: boolean;
  templateCreateLoading: boolean;
  deleteMultipleNode: boolean;
  contextVariables: {subFields: string[],subFieldValue: Record<string, string[]>};
  isAddingCardsFromTemplate: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Chatbot5Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  currentDeletedNodeId: number | null = null;
  currentTargetNodeId: number | null = null;
  currentSourceId: string = "";
  nodeDeleteTitle: string = "";
  headerRef = React.createRef<HTMLElement>();
  sidebarTop: number | undefined = undefined;
  nodeIdsToBeDeleted: number[] = [];
  criteriaRoutingId: number | undefined = undefined;
  connectorCardCallId: string | null = null;
  botContext: string[] = [];
  criteriaRoutingCardId: number | undefined = undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleShowCardsDrawer = this.handleShowCardsDrawer.bind(this)

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationScreenNameMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isDrawerOpen: false,
      selectedCard: null,
      actions: [],
      selectedNodeData: undefined,
      token: "",
      connectorCardLoading: false,
      source: [],
      selectedNodeId: undefined,
      cardDeleteLoading: false,
      openDeleteConfirmDialog: false,
      showChatbotErrorDialog: false,
      hasErrorInTheChatbot: false,
      templateCreateLoading: false,
      deleteMultipleNode: false,
      contextVariables: {subFields: [], subFieldValue: {}},
      isAddingCardsFromTemplate: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleFetchConnectorCards = this.handleFetchConnectorCards.bind(this)
    this.handleRestApiResponse = this.handleRestApiResponse.bind(this)
    this.handleConnectorCardResponse = this.handleConnectorCardResponse.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleCardNodeClick = this.handleCardNodeClick.bind(this)
    this.handleConnectorCardDeleteResponse = this.handleConnectorCardDeleteResponse.bind(this)
    this.handleRemoveSourceFromConnectorCard = this.handleRemoveSourceFromConnectorCard.bind(this)
    this.handleUpdateSourceInConnectorCardResponse = this.handleUpdateSourceInConnectorCardResponse.bind(this)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleRestApiResponse(message)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
    this.setState({token})
    this.handleFetchConnectorCards(token)
  }

  handleRestApiResponse(message: Message) {
    const responseHandlers: Record<string, (newMsg: Message) => void> = {}
    const {title} = message.getData(getName(MessageEnum.NavigationPropsMessage))
    const messageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    const isMessageIdEqualToConnectorCallId = this.connectorCardCallId !== null && this.connectorCardCallId === messageId

    if(title === "DeleteConnectorCard") {
      responseHandlers["DeleteConnectorCard"] = this.handleConnectorCardDeleteResponse
    }

    if(title === "RemoveSourceFromConnectorCardData") {
      responseHandlers["RemoveSourceFromConnectorCardData"] = this.handleRemoveSourceFromConnectorCard
    }

    if(title === "SourceUpdateInConnectorCard") {
      responseHandlers["SourceUpdateInConnectorCard"] = this.handleUpdateSourceInConnectorCardResponse
    }

    if(title === "CreateTemplateWithDefaultNodeIDs") {
      responseHandlers["CreateTemplateWithDefaultNodeIDs"] = this.handleCreateTemplateResponse
    }

    if(title === "DeleteMultipleCards") {
      responseHandlers["DeleteMultipleCards"] = this.handleDeleteMultipleCardsResponse
    }

    if(title === "AddConnectorCardsFromTemplate") {
      responseHandlers["AddConnectorCardsFromTemplate"] = this.handleAddConnectorCardsFromTemplateToChatbotResponse
    }

    if(isMessageIdEqualToConnectorCallId || title === "ConnectorCard") {
      this.handleConnectorCardResponse(message)
      this.connectorCardCallId = null
    }

    const handler = responseHandlers[title]
    if(handler) {
      handler(message)
    }
  }

  handleCreateTemplateResponse = async(message: Message) => {
    this.setState({templateCreateLoading: false})
    const templateRes = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

    if(!templateRes) {
      toast.error("Failed to create template!", {className: "error__toast"})
      return;
    }

    if(templateRes.errors?.[0] === "Card templates cannot exceed 12 card templates") {
      toast.error("You already added the maximum number of templates", {className: "error__toast"})
      return;
    }

    if(templateRes.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(templateRes.data) {
      const navigationMessage = new Message(getName(MessageEnum.NavigationProjectTemplatesEditMessage))
      navigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      )
      navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), templateRes.data.id)
      this.send(navigationMessage)
    }
  }

  handleDeleteMultipleCardsResponse = async(message: Message) => {
    this.setState({cardDeleteLoading: false})
    const deleteRes = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!deleteRes) {
      toast.error("Failed to delete cards!", {className: "error__toast"})
      return;
    }

    if(deleteRes.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(deleteRes.success) {
      toast.success("Cards successfully deleted",{className: "success__toast"})
      const nodeIdsWithSet = new Set([...this.nodeIdsToBeDeleted])
      const newActions = this.state.actions.filter(action => !nodeIdsWithSet.has(action.id))
      const newActionsWithFilteredSource: NodeData[] = newActions.map((action) => {
        const areDeletedNodesPartOfCurrentNodeSource = action.source.some(sourceId => nodeIdsWithSet.has(+sourceId))
        const newSource = action.source.filter(sourceId => !nodeIdsWithSet.has(+sourceId))
        const status = newSource.length > 0 ? "connected" : "disconnected"
        return {
          ...action,
          source: newSource,
          status: !areDeletedNodesPartOfCurrentNodeSource ? action.status : status
        }
      })
      this.nodeDeleteTitle = "";
      this.nodeIdsToBeDeleted = []
      this.handleSaveIntoBotContext(newActionsWithFilteredSource)
      const contextVariables = utils.getContextVariableFromNodes(newActionsWithFilteredSource)
      this.setState({
        actions: newActionsWithFilteredSource, 
        selectedCard: null, 
        openDeleteConfirmDialog: false, 
        selectedNodeId: undefined,
        selectedNodeData: undefined, 
        isDrawerOpen: false,
        deleteMultipleNode: false,
        contextVariables: contextVariables
      })
    }
  }

  async handleConnectorCardResponse(message: Message) {
    this.setState({connectorCardLoading: false})
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!responseJson) {
      toast.error("Failed to fetch data", {className: "error__toast"})
      return;
    }
    if(responseJson.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    } 
    if(responseJson.data) {
      const connectorCards = responseJson.data as ConnectorCard[]
      const newActions = connectorCards.map((action) => utils.getNodeDataFromConnectorCardAttributes(action))
      this.handleSaveIntoBotContext(newActions)
      const context = utils.getContextVariableFromNodes(newActions)
      this.setState({actions: newActions, contextVariables: context})
    }
  }

  async handleUpdateSourceInConnectorCardResponse(message: Message){
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!response) {
      toast.error("Internal server error", {className: "error__toast"})
      if(this.currentTargetNodeId) {
        const currentTargetNodeIndex = this.state.actions.findIndex(action => action.id === this.currentTargetNodeId)
        const currentTargetNode = this.state.actions[currentTargetNodeIndex]
        const newSourceInCurrentNode = currentTargetNode.source.filter(sourceId => sourceId !== this.currentSourceId)
        const newCurrentTargetNode: NodeData = {
          ...currentTargetNode,
          source: newSourceInCurrentNode
        }

        const newActions = [...this.state.actions]
        newActions[currentTargetNodeIndex] = newCurrentTargetNode
        if(this.criteriaRoutingId) {
          this.handleUpdateCriteriaRouting({
            newActions,
            criteriaCardId: +this.currentSourceId,
            criteriaRoutingId: this.criteriaRoutingId,
            nextCardId: null
          })
        }
        this.currentTargetNodeId = null
        this.currentSourceId = ""
        this.criteriaRoutingId = undefined
        this.setState({actions: newActions})
      }
      return;
    }
    if(response.errors?.[0]?.token) {
      await this.handleLogout()
    }

    this.currentSourceId = ""
    this.currentTargetNodeId = null
    this.criteriaRoutingId = undefined
  }

  async handleRemoveSourceFromConnectorCard(message: Message){
    const {criteriaRoutingId, cardId}= message.getData(getName(MessageEnum.NavigationPropsMessage))
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if(!response) {
      toast.error("Internal server error", {className: "error__toast"})
      this.handleFetchConnectorCards(this.state.token)
      return;
    }
    if(response.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }
    if(response.connector_card) {
      const actionId = response.connector_card.data.attributes.id 
      const currentActionIndex = this.state.actions.findIndex(action => action.id === actionId)
      const newActions = [...this.state.actions]
      newActions[currentActionIndex] = utils.getNodeDataFromConnectorCardAttributes(response.connector_card.data)
      this.handleUpdateCriteriaRouting({
        newActions,
        criteriaCardId: cardId,
        criteriaRoutingId: criteriaRoutingId,
        nextCardId: null
      })
      this.setState({actions: newActions})
    }
  }

  async handleConnectorCardDeleteResponse(message: Message){
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    this.setState({cardDeleteLoading: false})
    if(!response) {
      toast.error("Failed to delete card!", {className: "error__toast"})
      return;
    }
    if(response.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }
    if(response.message) {
      toast.success("The card successfully deleted!", {className: "success__toast"})
      const newActions = utils.getFilteredNodeData(this.currentDeletedNodeId,this.state.actions)
      this.currentDeletedNodeId = null;
      this.nodeDeleteTitle = "";
      this.handleSaveIntoBotContext(newActions)
      const contextVariables = utils.getContextVariableFromNodes(newActions)
      if(this.criteriaRoutingCardId && this.criteriaRoutingId) {
        const criteriaRoutingCardIndex = newActions.findIndex(action => action.id === this.criteriaRoutingCardId)
        const criteriaRoutingCard = newActions[criteriaRoutingCardIndex]
        let criteriaRoutings = [...criteriaRoutingCard.criteriaRoutings!]
        const criteriaRoutingRuleIndex = criteriaRoutings.findIndex(rule => rule.criteria_routing_id === this.criteriaRoutingId)
        criteriaRoutings[criteriaRoutingRuleIndex] = {
          ...criteriaRoutings[criteriaRoutingRuleIndex],
          nextCardId: null,
        }
        criteriaRoutingCard.criteriaRoutings = criteriaRoutings
        newActions[criteriaRoutingCardIndex] = criteriaRoutingCard
        this.criteriaRoutingCardId = undefined
        this.criteriaRoutingId = undefined
      }
      this.setState({
        actions: newActions, 
        openDeleteConfirmDialog: false, 
        selectedCard: null, 
        selectedNodeData: undefined, 
        selectedNodeId: undefined,
        isDrawerOpen: false,
        contextVariables: contextVariables
      })
    }
  }

  handleAddConnectorCardsFromTemplateToChatbotResponse = async(message: Message) => {
    this.setState({isAddingCardsFromTemplate: false})
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

    if(!response) {
      toast.error("Failed to add cards from template", {className: "error__toast"})
      return;
    }

    if(response.errors?.[0]?.token) {
      await this.handleLogout()
      return;
    }

    if(response.data) {
      const connectorCards: Array<{data: ConnectorCard}> = response.data.attributes.connector_cards
      const newActions = connectorCards.map((connectorCard) => {
        const cardData = utils.getNodeDataFromConnectorCardAttributes(connectorCard.data)
        return cardData
      })
      this.criteriaRoutingId = undefined
      const contextVariables = utils.getContextVariableFromNodes(newActions)
      this.setState({actions: newActions, contextVariables})
    }
  }

  handleFetchConnectorCards(token: string) {
    this.setState({connectorCardLoading: true})
    const chatbotId = this.props.navigation.getParam("navigationBarTitleText")

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    }
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    )

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.connectorCardsEndPoint}?${this.props.isTemplate ? "card_template_id" : "chatbot_id"}=${chatbotId}`
    )

    reqMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {title: "ConnectorCard"}
    )

    this.connectorCardCallId = reqMessage.messageId

    this.send(reqMessage)

  }

  handleSaveIntoBotContext = (actions: NodeData[]) => {
    const allFieldNames = actions.map(action => action.fieldName)
    this.botContext = allFieldNames.filter((name): name is string => typeof name === "string" && name.length !== 0)
  }
  
  handleShowCardsDrawer(nodeData: Omit<NodeData, "status" | "onClick">){
    this.criteriaRoutingId = nodeData.criteriaRoutingId
    if(this.state.isDrawerOpen) {
      this.setState({source: nodeData.source, selectedCard: null, selectedNodeData: undefined, selectedNodeId: undefined})
      return;
    }
    this.sidebarTop = this.headerRef.current?.offsetHeight
    this.setState({ isDrawerOpen: true, source: nodeData.source });
  };

  handleDrawerClose=()=>{
    this.setState({ 
      isDrawerOpen: false, 
      selectedCard: null, 
      selectedNodeData: undefined, 
      source: [],
      selectedNodeId: undefined, 
    })
  }

  handleCardNodeClick(data: Omit<NodeData,"status" | "onClick">) {
    this.sidebarTop = this.headerRef.current?.offsetHeight
    if(this.state.selectedNodeId === data.id && this.state.selectedCard === data.type) {
      return;
    }
    this.setState({
      isDrawerOpen: true, 
      selectedNodeData: {
        [data.id]: data
      },
      selectedNodeId: data.id, 
      selectedCard: data.type,
      source: data.source,
    })
  }

  handleOpenConfirmationDialog = (node: {
    id: number;
    title: string;
    source: string[];
    criteriaRoutingId?: number;
}) => {
    this.currentDeletedNodeId = node.id;
    this.nodeDeleteTitle = node.title;
    this.criteriaRoutingId = node.criteriaRoutingId
    this.criteriaRoutingCardId = this.state.actions.find(action => node.source.includes(`${action.id}`) && action.type === "criteria_routing")?.id
    this.setState({openDeleteConfirmDialog: true})
  }

  handleCloseConfirmationDialog = () => {
    this.currentDeletedNodeId = null;
    this.nodeDeleteTitle = "";
    this.nodeIdsToBeDeleted = [];
    this.setState({openDeleteConfirmDialog: false, deleteMultipleNode: false})
  }

  handleDeleteCardNode = () => {
    this.setState({cardDeleteLoading: true})

    const deleteMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    if(this.state.deleteMultipleNode) {
      const body = {
        connector_card: {
          card_ids: this.nodeIdsToBeDeleted
        },
      }

      deleteMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      deleteMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteMultipleCards
      )

      deleteMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        {title: "DeleteMultipleCards"}
      )
    } else {
      deleteMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.connectorCardsEndPoint}/${this.currentDeletedNodeId}`
      )
  
      deleteMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        {title: "DeleteConnectorCard"}
      )
    }

    deleteMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: this.state.token,
        'Content-Type': configJSON.validationApiContentType
      })
    )

    deleteMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    )

    
    this.send(deleteMessage)
  }

  handleAddCardData = (newAction: NodeData) => {
    let newActions = [...this.state.actions]
    if(this.criteriaRoutingId) {
      const critriaCardIndex = newActions.findIndex(action => action.id === +this.state.source[0])
      const criteriaCard = newActions[critriaCardIndex]
      const criteriaRoutingIndex = criteriaCard.criteriaRoutings!.findIndex(criteria => criteria.criteria_routing_id === this.criteriaRoutingId)
      criteriaCard.criteriaRoutings![criteriaRoutingIndex] = {
        ...criteriaCard.criteriaRoutings![criteriaRoutingIndex],
        nextCardId: `${newAction.id}`
      }
      newActions[critriaCardIndex] = criteriaCard
      newActions.push(newAction)
    } else {
      newActions = utils.addOrEditActions(newActions, newAction)
    }
    this.criteriaRoutingId = undefined
    this.handleSaveIntoBotContext(newActions)
    const contextVariables = utils.getContextVariableFromNodes(newActions)
    this.setState({
      actions: newActions,
      selectedNodeData: {[newAction.id]: newAction}, 
      selectedNodeId: newAction.id,
      source: [],
      contextVariables: contextVariables
    })
  }

  handleNavigateToBotSettingsPage = () => {
    const message = new Message(getName(MessageEnum.NavigationSettingsMessage))
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    message.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      this.props.navigation.getParam("navigationBarTitleText")
    )
    this.send(message)
  }

  async handleLogout() {
    await removeStorageData("authToken")
    await removeStorageData("userId")
    sessionStorage.clear()

    const navigationMesaage = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
    navigationMesaage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {navigation: this.props.navigation}
    )
    this.send(navigationMesaage)
  }

  handleNavigateToBotsPage = () => {
    const navigationMessage = new Message(getName(MessageEnum.NavigationBotsMessage))
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {navigation: this.props.navigation}
    )
    this.send(navigationMessage)
  }

  handlePublishBot = () => {
    if(this.state.actions.length === 0) {
      toast.error("Please add a card", {className: "error__toast"})
      return;
    }
    const initialHomeNode: Node<NodeData> = {
      id: 'start', 
      type: "homeNode", 
      position: { x: 24, y: 24 },
      data: {
          title: "Home",
          message: "Home",
          status: "disconnected",
          source: [],
          type: "send_message",
          id: Date.now(),
          connectorCardType: this.props.isTemplate ? "template" : "chatbot"
      },
    }
    let nodeCardData: Node<NodeData>[] = [initialHomeNode]
    this.state.actions.forEach((nodeData) => {
      nodeCardData.push({
        id: `${nodeData.id}`,
        position: {x: 0, y: 0},
        type: "cardNode",
        data: {
            ...nodeData,
            onClick: this.handleCardNodeClick,
            onCardNodeDelete: this.handleOpenConfirmationDialog
        },
      })
    })
    const {newNodes} = nodeHelpers.getNewNodesWithNewEdges({
      nodes: nodeCardData,
      onAddNodeClick: this.handleShowCardsDrawer,
      checkForError: true
    })

    const hasError = newNodes.some(node => !!node.data.error)

    if(hasError) {
      let newActions: NodeData[] = []
      newNodes.forEach(node => {
        if(node.id !== "start" && !node.id.startsWith("end")) {
          newActions.push(node.data)
        }
      })
      this.setState({showChatbotErrorDialog: true, hasErrorInTheChatbot: true})
    }

  }

  handleOpenSelectedCard = (value: SelectedCard) => {
    this.setState({selectedCard: value})
  }

  handleCloseSelectedCard = () => {
    this.setState({selectedCard: null})
  }

  handleCloseChatbotErrorDialog = () => {
    this.setState({showChatbotErrorDialog: false})
  }

  onTemplateCreate = (nodes: Node<NodeData>[], name: string) => {
    const nodeIds = nodes.map(node => node.data.id)
    const body = {
      card_template: {
        name: name,
        connector_cards_ids: nodeIds
      },
    }
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.validationApiContentType
    }
    this.setState({templateCreateLoading: true})
    const apiReqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    apiReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )
    apiReqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    apiReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    apiReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTemplateEndpoint
    )
    apiReqMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {title: "CreateTemplateWithDefaultNodeIDs"}
    )
    this.send(apiReqMessage)
  }

  handleUpdateCriteriaRouting = ({
    newActions,
    criteriaCardId,
    criteriaRoutingId,
    nextCardId
  }: UpdateCriteriaRoutingArgs) => {
    const critriaCardIndex = newActions.findIndex(action => action.id === criteriaCardId)
    if(critriaCardIndex !== -1) {
      const criteriaCard = newActions[critriaCardIndex]
      const criteriaRoutingIndex = criteriaCard.criteriaRoutings!.findIndex(criteria => criteria.criteria_routing_id === criteriaRoutingId)
      criteriaCard.criteriaRoutings![criteriaRoutingIndex] = {
        ...criteriaCard.criteriaRoutings![criteriaRoutingIndex],
        nextCardId: nextCardId
      }
      newActions[critriaCardIndex] = criteriaCard
    }
  }

  handleConnectionTypeCard = (nodeId: number, criteriaRoutingId?: number) => {
    const currentNodeIndex = this.state.actions.findIndex(action => action.id === nodeId)
    const currentNode = this.state.actions[currentNodeIndex]
    const newActions = [...this.state.actions]
    newActions[currentNodeIndex] = {
      ...currentNode,
      source: [...currentNode.source, ...this.state.source],
      status: "connected",
      criteriaRoutingId: criteriaRoutingId
    }

    if(criteriaRoutingId) {
      this.handleUpdateCriteriaRouting({
        newActions,
        criteriaRoutingId,
        criteriaCardId: +this.state.source[0],
        nextCardId: `${nodeId}`
      })
    }

    this.setState({
      actions: newActions,
      isDrawerOpen: false,
      selectedCard: null,
      selectedNodeData:undefined,
      selectedNodeId: undefined,
      source: []
    })
  }

  handleConnectNode = (targetNodeId: string, source: string[], criteriaRoutingId?: number) => {
    this.setState({source: source})
    this.handleConnectionTypeCard(+targetNodeId, criteriaRoutingId)
    this.currentTargetNodeId = +targetNodeId
    this.currentSourceId = source[0]
    this.criteriaRoutingId = criteriaRoutingId
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.validationApiContentType
    }

    const body: Record<string, unknown> = {
      card_id: targetNodeId,
      connector_card_ids: +source[0],
    }

    if(criteriaRoutingId) {
      body["previous_card_id"] = criteriaRoutingId
    }

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateSource
    )

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    )

    requestMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {title: "SourceUpdateInConnectorCard"}
    )

    this.send(requestMsg)
  }

  handleDeleteMultipleCards = (nodes: Node<NodeData>[]) => {
    const nodeTitle: string[] = [];

    nodes.forEach((node) => {
      this.nodeIdsToBeDeleted.push(node.data.id)
      nodeTitle.push(node.data.title)
    })

    this.nodeDeleteTitle = nodeTitle.join(", ")
    this.setState({openDeleteConfirmDialog: true, deleteMultipleNode: true})
  }

  handleAddConnectorCardsFromTemplateToChatbot = (nodeIds: number[]) => {
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage))

    const chatbotId = this.props.navigation.getParam("navigationBarTitleText")

    const header = {
      token: this.state.token,
      "Content-Type": "application/json"
    }
    const body: Record<string, number[] | string | number> = {
      connector_cards_ids: nodeIds,
      source_id: this.state.source[0]
    }

    if(this.criteriaRoutingId) {
      body.previous_card_id = this.criteriaRoutingId
    }

    this.setState({isAddingCardsFromTemplate: true})

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.chatbotEndpoint}/${chatbotId}/create_connector_cards_for_chatbot`
    )

    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    )

    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {title: "AddConnectorCardsFromTemplate"}
    )

    this.send(message)
  }

  // Customizable Area End
}
