import { Link, url_regex } from "./components/ShareLink.web";
import {NodeData} from "../../../components/src/CardNode.web";
import { ConnectorCard } from "./Chatbot5Controller";

const checkLinkNameAndURLError = (links: Link[]) => {
    const hasError = links.some((link) => {
        const nameError = link.name.trim().length === 0
        const urlError = !url_regex.test(link.url)
        return nameError || urlError
    })
    return hasError
}

const addOrEditActions = (actions: NodeData[], newAction: NodeData) => {
  const newActions = [...actions]
  const actionIndex = newActions.findIndex(action => action.id === newAction.id)
  if(actionIndex === -1) {
    newActions.push(newAction)
  } else {
    newActions[actionIndex] = { ...newActions[actionIndex], ...newAction }
  }
  return newActions
}

const getCriteriaRoutings = (criteriaRoutings: ConnectorCard["attributes"]["criteria_routings"]) => {
  return criteriaRoutings.map((criteriaRouting) => {
    return {
      title: criteriaRouting.criteria_routing_title,
      criteria_routing_id: criteriaRouting.criteria_routing_id,
      nextCardId: criteriaRouting.next_card_id ? `${criteriaRouting.next_card_id}`: null,
      rules: criteriaRouting.rules.map(rule => ({
        rule_id: rule.rule_id,
        field_name: rule.field_name,
        comparator: rule.comparator,
        values: rule.values,
        operator_with_previous_index: rule.operator_with_previous_index,
        sub_field: rule.sub_field ?? undefined
      }))
    }
  })
}

const getContextVariableFromNodes = (nodes: NodeData[]) => {
  const subFields: string[] = []
  const subFieldValue:Record<string, string[]> = {}
  nodes.forEach((node) => {
    if(node.fieldName) {
      subFields.push(node.fieldName)
      let options: string[] = [];
      if(node.type === "slider_scale") {
        const minValue = +node.minValue!
        const maxValue = +node.maxValue!
        for(let value = minValue; value <= maxValue; value++) {
          options.push(`${value}`)
        }
        subFieldValue[node.fieldName] = options
      } else if(node.type === "rating") {
        options = ["1","2","3","4","5"]
      } else if(node.type === "single_choice" || node.type === "multi_choice" || node.type ==="dropdown") {
        options = node.options!
      }
      subFieldValue[node.fieldName] = options
    }
  })

  return {subFields, subFieldValue}
}

const getNodeDataFromConnectorCardAttributes = (connectorCardData: ConnectorCard) => {
  const {attributes} = connectorCardData
        const newAction: NodeData = {
          id: attributes.id,
          title: attributes.name,
          message: attributes.message ?? "",
          type: attributes.card_type,
          status: attributes.status ?? "disconnected",
          source: attributes.source,
          fieldName: attributes.field_name ?? undefined,
          minValue: attributes.minimum_selection ? `${attributes.minimum_selection}` : undefined,
          maxValue: attributes.maximum_selection ? `${attributes.maximum_selection}` : undefined,
          videoURL: attributes.video_link ?? undefined,
          waitTime: attributes.card_wait_time ?? undefined,
          targetCard: attributes.target_card ?? undefined,
          cardType: attributes.connection_type ?? undefined,
          userGuides: attributes.user_guides,
          embedForms: attributes.embeded_forms,
          options: attributes.option,
          connectorCardType: attributes.connector_card_type,
          criteriaRoutingId: attributes.previous_card_id ?? undefined,
          links: attributes.connector_card_links.map((link) => ({
            id: link.id,
            name: link.link_name,
            linkType: link.link_type,
            openLink: link.open_link,
            icon: link.connector_icon_image_link,
            url: link.link_url
          })),
          criteriaRoutings: getCriteriaRoutings(attributes.criteria_routings)
        }
        return newAction
}

const getTargetCardOptionsForGoToTileCard = (actions: NodeData[], source: number) => {
  return actions.filter(action => action.id !== source).map(action => ({label: action.title, value: `${action.id}`}))
}

const getFilteredNodeData = (nodeId: number | null, nodeData: NodeData[]) => {
  const filteredNodes = nodeData.filter(node => node.id !== nodeId)
  const filteredNodesWithoutNodeIdSource: NodeData[] = filteredNodes.map(node => {
    const isDeleteNodePartOfCurrentNode = node.source.includes(`${nodeId}`)
    const newSource = node.source.filter(sourceId => sourceId !== `${nodeId}`)
    const status = newSource.length > 0 ? "connected" : "disconnected"
    return {
      ...node,
      source: newSource,
      status: !isDeleteNodePartOfCurrentNode ? node.status : status
    }
  })
  return filteredNodesWithoutNodeIdSource
} 

const getTransformedLinks = (linkExpandedStatus: Map<string, boolean>,links?: NodeData["links"]): Link[] => {
  if(!links) {
      return [
        {
          linkId: "link1",
          title: "Link 1",
          name: "Link 1",
          linkType: "regular",
          openLink: "_blank",
          url: "https://",
          isExpanded: true,
        }
      ]
  }

  const newLinks: Link[] = links.map((link, linkIndex) => ({
      id: link.id,
      linkId: `link${linkIndex + 1}`,
      title: `Link ${linkIndex + 1}`,
      name: link.name,
      linkType: link.linkType,
      openLink: link.openLink,
      icon: link.icon,
      url: link.url ?? "https://",
      isExpanded: !!linkExpandedStatus.get(link.name)
  }))

  return newLinks
}

export const utils = {
    checkLinkNameAndURLError,
    addOrEditActions,
    getNodeDataFromConnectorCardAttributes,
    getFilteredNodeData,
    getTargetCardOptionsForGoToTileCard,
    getTransformedLinks,
    getContextVariableFromNodes
}
